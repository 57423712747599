@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-switch;
@include foundation-table;
// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
@include foundation-tooltip;

// Containers
@include foundation-accordion;
@include foundation-media-object;
@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;


@keyframes photozoomin {
    0% {
    	opacity: 0; 
    	transform: translateY(0) scale(1.3); 
    	// filter: blur(0px);
    }
    100% { 
    	opacity: 1; 
    	transform: translateY(0) scale(1); 
    	// filter: blur(0);
    }
}
@keyframes fadeinquick {
    0% {
    	opacity: 0;
    }
    75% {
    	opacity: 0;
    }
    100% { 
    	opacity: 1;
    }
}
@keyframes fadein {
    0% {
    	opacity: 0;
    }
    100% { 
    	opacity: 1;
    }
}
.css-animation-fadein {
  animation: fadein 0.5s cubic-bezier(0.5, 0.2, 0.9, 0.4);
}
@keyframes fadetodark {
    0% {
    	background-color: rgba(0,0,0,0);
    }
    100% { 
    	background-color: rgba(0,0,0,0.8);
    }
}

@keyframes slideInRight {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}



///////



// Styles


.mt-0 { margin-top: 0rem; }
.mt-05 { margin-top: 0.5555rem; }
.mt-1 { margin-top: 1.1111rem; }
.mt-2 { margin-top: 2.2222rem; }
.mt-3 { margin-top: 3.3333rem; }
.mt-4 { margin-top: 4.4444rem; }
.mt-5 { margin-top: 5.5555rem; }

.mr-0 { margin-right: 0rem; }
.mr-05 { margin-right: 0.5555rem; }
.mr-1 { margin-right: 1.1111rem; }
.mr-2 { margin-right: 2.2222rem; }
.mr-3 { margin-right: 3.3333rem; }
.mr-4 { margin-right: 4.4444rem; }
.mr-5 { margin-right: 5.5555rem; }

.mb-0 { margin-bottom: 0rem; }
.mb-05 { margin-bottom: 0.5555rem; }
.mb-1 { margin-bottom: 1.1111rem; }
.mb-2 { margin-bottom: 2.2222rem; }
.mb-3 { margin-bottom: 3.3333rem; }
.mb-4 { margin-bottom: 4.4444rem; }
.mb-5 { margin-bottom: 5.5555rem; }

.ml-0 { margin-left: 0rem; }
.ml-05 { margin-left: 0.5555rem; }
.ml-1 { margin-left: 1.1111rem; }
.ml-2 { margin-left: 2.2222rem; }
.ml-3 { margin-left: 3.3333rem; }
.ml-4 { margin-left: 4.4444rem; }
.ml-5 { margin-left: 5.5555rem; }

.p-1 { padding: 1.1111rem; }
.p-2 { padding: 2.2222rem; }
.p-3 { padding: 3.3333rem; }


.bg-black {
	background: black;
}
.height-100 {
	min-height: 100vh;
}
.height-75 {
	min-height: 75vh;
}
.height-50 {
	min-height: 50vh;
	& > .columns {
		position: relative;
	}
}
.height-square {
	height: 0;
	padding-bottom: 100%;
	position: relative;
}
.vertical-center {
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	transform: translateY(-50%);
}


////////

$pinkish-lightest: #ece0e4;
$pinkish: #d36981;

html {
}
body {
	// font-weight: lighter;
	font-weight: 300;
	font-size: 26px;
	line-height: 1.6;
	// -ms-transform-origin: 50% 0%; /* IE 9 */
 //  -webkit-transform-origin: 50% 0%; /* Chrome, Safari, Opera */
 //  transform-origin: 50% 0%;
 //  box-shadow: 0 0 100px rgba(0,0,0,0.2);
	
	@include breakpoint(small only) {
		font-size: 20px;
	}
	// @include breakpoint(large) {
	// 	font-size: 2.5vw;
	// }
}

b {
	// font-weight: 900;
}
a {
	&:hover {
		color: $pinkish;
	}
}

h2 {
	margin-bottom: 100px;
    font-weight: bold;
}
h4 {
	font-size: 100%;
}
h5 {
	font-size: 100%;
}
.centered {
    text-align: center;
}


section,
header {
    @include breakpoint(small only) {
        padding: 0 20px;
    }
    @include breakpoint(medium only) {
        padding: 0 30px;
    }
}
.content {
    margin: 30px auto;
    width: 100%;
    max-width: 760px;
}

.zigzag-text {
	display: flex;
	align-content: center;
	align-items: center;
	// min-height: 50vw;

	p {
		// margin: 100px auto;
		// max-width: 550px;
	}
}
.zigzag-img {
	height: 100%;
	display: flex;
	// align-content: center;
	justify-content: center;
	align-items: center;
}

.profile {
    display: flex;
    align-items: center;

    @include breakpoint(small only) {
        flex-direction: column;
    }

	h1 {
	    padding-top: 0px;
	    margin-bottom: 10px;
	    padding-bottom: 0;
	    font-size: 70px;
	    font-weight: bold;
        line-height: 1em;

        @include breakpoint(small only) {
            font-size: 14vw;
            margin-top: 40px;
            margin-bottom: 40px;
        }
	}
}
.profile-pic {
    width: 200px;
    height: 200px;
    border-radius: 100%;
    // border: 1px solid rgba(50,50,50,0.1);
    position: relative;
    float: left;
    overflow: hidden;
    margin-right: 40px;

    background-image: url('../img/peedu-tuisk-pointing.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 45% 0%;

    @include breakpoint(small only) {
        width: 60vw;
        height: 60vw;
        margin-right: 0;
    }
}

.profile-link {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.profile-link li {
    font-size: 30px;

    @include breakpoint(small only) {
        width: 25%;
        text-align: center;
        margin-bottom: 10px;
    }
}
.profile-link li a {
}

.container {
	// background-color: #fff;
	position: relative;
	padding-top: 5.555rem;
	padding-bottom: 5.555rem;
	
	&--dark {
		background-color: rgba(0,0,0,0.9);
		color: #fff;
	}
	&--grey {
		background-color: darken(#fff, 3%);
	}
}



////////////


.logos {
    background: rgba(150,150,150,0.1);
    padding-top: 100px;
    padding-bottom: 100px;
}
.logolist {
    /*max-width: 700px;*/
    /*background-color: rgba(150,150,150,0.2);*/
    list-style: none;
    padding: 0;
    margin: 0 0 0 0;
/*    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
*/
    display: grid;
    grid-template-columns:  1fr 1fr 1fr 1fr;
    grid-template-rows:  1fr 1fr 1fr 1fr;
    /*grid-column-gap: 2rem;*/
    grid-row-gap: 3rem;
    /*column-gap: 2rem;*/
    row-gap: 3rem;

    @include breakpoint(small only) {
        grid-template-columns:  1fr 1fr;
    }
    @include breakpoint(medium only) {
        grid-template-columns:  1fr 1fr 1fr;
    }
    
}
.logolist li {
    display: flex;
    align-items: center;
    justify-content: center;
    /*height: 15vw;*/
    /*flex: 1 1 auto;*/
    /*display: inline-block;*/
    text-align: center;
    /*max-height: 45px;*/
    /*width: auto;*/
    /*max-width: 30%;*/
    /*padding: 2rem;*/
    /*border: 1px solid rgba(0,0,0,0.1);*/
    margin-bottom: 0rem;

    &.othersnda {
        @include breakpoint(medium only) {
            grid-column-start: 2;
        }
    }

}
.logolist li + li {
    /*margin-left: 2rem;*/
}
.logolist li img {
    // width: 100%;
    max-width: 60%;
    max-height: 6vw;
    /*max-width: 33%;*/
    /*filter: saturate(0);*/

    @include breakpoint(small only) {
        max-width: 70%;
        max-height: 15vw;
    }
}
.text-othersnda {
    opacity: 0.5;
    font-size: 0.8em;
}


/* ------------------------------ */

.quotes {
    padding-top: 100px;
    padding-bottom: 100px;
}
.quotelist {
    list-style: none;
    padding: 0;
    margin: 0;
    position: relative;
}
.quotelist li {
    /*box-shadow: 0 10px 40px rgba(150,150,150,0.2);*/
    transition: opacity 0.5s;
    outline: none;
}
.quotelist li[aria-hidden="true"] {
    opacity: 0;
}
.quotelist q {
    
}
.quotelist q:before {
    content: '“';
    font-family: georgia, serif;
    font-size: 30px;
    line-height: 1em;
}
.quotelist q:after {
    content: '”';
    font-family: georgia, serif;
    font-size: 30px;
    line-height: 1em;
}
.quotelist h5 {
    margin-top: 6rem;
    margin-bottom: 0;
    padding-bottom: 0;
}
.quotelist p {
    margin-top: 0.5em;
    font-size: 70%;
}
.quotelist .q-profile {
    /*margin-top: 1rem;*/
}
.quotelist .q-profile img {
    border: 1px solid rgba(150,150,150,0.1);
    width: 100px;
    border-radius: 100%;
    float:left;
    margin-right: 1rem;
}
.slick-arrow {
    /*border: 0;*/
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 100px;
    height: 100px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
    border-radius: 100%;
    transition: background-color 0.2s;

    @include breakpoint(small only) {
        display: none !important;
    }
    @include breakpoint(medium only) {
        display: none !important;
    }
}
.slick-arrow:hover {
    background-color: transparentize($primary-color, 0.9);
}
.slick-prev {
    left: -30%;
}
.slick-prev:before {
    content: '←';
    /*font-family: 'slick';*/
    position: relative;
    top: -0.1em;
    font-size: 40px;
    line-height: 1;
    // opacity: .75;
    // color: black;
    color: $primary-color;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-next {
    right: -30%;
}
.slick-next:before {
    content: '→';
    /*font-family: 'slick';*/
    position: relative;
    top: -0.1em;
    font-size: 40px;
    line-height: 1;
    // opacity: .75;
    // color: black;
    color: $primary-color;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots {
    list-style: none;
    display: flex;
    justify-content: center;
    margin-top: 60px;
}
.slick-dots li {

}
.slick-dots li button {
    color: transparent;
    border: none;
    outline: none;
    border-radius: 20px;
    margin: 0 10px;
    height: 4px;
    width: 35px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    background: transparentize($primary-color, 0.9);
    transition: background 0.2s;
}
@keyframes anim-dot {
    from { width: 0%; }
    to {   width: 100%; }
}
.slick-dots li button[aria-selected="true"]:before {
    /*content: '';*/
    width: 0;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background: transparentize($primary-color, 0.9);
    animation: anim-dot 3.5s;
}
.slick-dots li button:hover {
    background: transparentize($primary-color, 0.3);
}
.slick-dots li button[aria-selected="true"] {
    background: transparentize($primary-color, 0.3);
}

/* ------------------------------ */

.summaries {
    background: rgba(150,150,150,0.1);
    padding: 100px 0;
}

.recents {
    padding-bottom: 100px;
}
.recent {
    list-style: none;
    /*padding: 0 2rem;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;*/
    display: grid;
    padding: 0 2rem;
    margin: 0;
    grid-template-columns:  1fr 1fr 1fr;
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    column-gap: 2rem;
    row-gap: 2rem;

    @include breakpoint(small only) {
        grid-template-columns:  1fr;
    }
    @include breakpoint(medium only) {
        grid-template-columns:  1fr;
    }
}
.recent li {
    /*width: calc(33.333% - 2rem);*/
    border-radius: 10px;
    background: white;
    box-shadow: 0 0 30px rgba(50,50,50,0.1);
    margin-bottom: 1rem;
    overflow: hidden;
}
.recent li .info {
    padding: 1rem 1.5rem;
}
.recent li .screenshot {
    position: relative;
    padding-bottom: 55%;
    overflow: hidden;
}
.recent li .screenshot img {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    min-width: 100%;
    height: 100%;
    width: auto;
    /*max-width: 150px;*/
    /*max-height: 30px;*/
    display: block;
    /*margin-bottom: 0.7rem;*/
    /*margin-top: 0.5rem;*/
}
.recent li h5 {
    margin: 0;
    padding: 0;
}
.recent li p {
    margin-top: 1rem;
    margin-bottom: 0rem;
    font-size: 80%;
}

.summaryblocks {
    list-style: none;
    padding: 0 2rem;
    margin: 0;
    display: grid;
    grid-template-columns:  1fr 1fr 1fr;
    /*grid-template-rows:  1fr 1fr 1fr;*/
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    column-gap: 2rem;
    row-gap: 2rem;
}
.summaryblocks > li {
    /*padding: 0.5rem;*/
}
.summaryblocks > li h4 {
    margin-bottom: 2rem;
}
.summaryblocks > li > ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.summaryblocks > li > ul li {
    border-radius: 10px;
    background: white;
    box-shadow: rgba(50,50,50,0.1);
    margin-bottom: 1rem;
    overflow: hidden;
    padding: 0.9rem 1.5rem;
}
.summaryblocks > li > ul li div {
    padding: 1rem 1.5rem;
}
.summaryblocks > li > ul li img {
    width: 100%;
    /*max-width: 150px;*/
    /*max-height: 30px;*/
    display: block;
    /*margin-bottom: 0.7rem;*/
    /*margin-top: 0.5rem;*/
}
.summaryblocks > li > ul li h5 {
    margin: 0;
    padding: 0;
}
.summaryblocks > li > ul li p {
    margin-top: 0rem;
    margin-bottom: 0rem;
    font-size: 80%;
}

/* ------------------------------ */


.portfolio-tags {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;
}
.portfolio-tags li {
    display: inline-block;
    padding: 5px 10px;
    margin: 10px;
}


.works {
    padding-top: 100px;
}
.workblocks {
    list-style: none;
    padding: 0 2rem;
    margin: 0;
    display: flex;
    /*display: grid;
    grid-template-columns:  1fr 1fr 1fr;
    grid-template-rows:  1fr 1fr 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    column-gap: 30px;
    row-gap: 30px;*/
}
.workblocks > li {

}
.workblocks > li > ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.workblocks > li > ul li {

}
.workblocks > li > ul li img {
    width: 100%;
    max-width: 150px;
    max-height: 30px;
}

///////


$cornerbutton-spacing: 5vh;
.backbutton,
.topbutton,
.downbutton {
	position: fixed;
	z-index: 3;
	font-size: 30px;
	color: rgba(130,130,130,0.3);
	&:hover {
		color: rgba(130,130,130,1);
		// background-color: rgba(255,255,255,0.1);
	}
}
.backbutton {
	top: $cornerbutton-spacing;
	left: $cornerbutton-spacing;
}
.topbutton {
	display: none;
	right: $cornerbutton-spacing;
	bottom: $cornerbutton-spacing;
}
.downbutton {
	right: $cornerbutton-spacing;
	bottom: $cornerbutton-spacing;
}




///////////////////


.video-js {
	position: absolute;
	top: 50%;
	left: 50%;
	min-height: 100% !important; 
	min-width: 100%;
	width: auto !important;
	transform: translate(-50%, -50%);
	// display: inlineblock;
}

.title-holder {
	position: absolute;
	top: 60%; //8vh;
	left: 0;
	right: 0;
	transform: translateY(-50%);
	z-index: 2;
}
.matude-tagline {
	left: 10%;
	right: 10%;
	font-size: 4vw;
	position: absolute;
	top: 60%;
	transform: translateY(-50%);
	font-weight: lighter;
	letter-spacing: 5px;
	text-shadow: 0 1px 2px rgba(0,0,0,0.2);
}
.dark-cover {
	z-index: 1;
	// content: '';
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(0,0,0,0.8);
	animation: fadetodark 1s cubic-bezier(0.5, 0.2, 0.9, 0.4);
}
.casestudy-header--ocdesk .dark-cover {
	background-color: rgba(0,0,0,0.5);
}

//

header.casestudy-header--ocdesk {
	width: 100%;
	height: 100vh;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center; 
	overflow: hidden;
	padding: 0;
	color: #fff;
	position: relative;

	@include breakpoint(small only) {
		background-image: url('../img/OCDock-minis2.JPG');
	}

	h2, h4 {
		color: inherit;
		font-weight: lighter;
		text-shadow: 0 1px 2px rgba(0,0,0,0.2);
		animation: fadeinquick 1s cubic-bezier(0.5, 0.2, 0.9, 0.4);
	}
	h2 {
		letter-spacing: 20px;
		margin-top: 2vh;
		margin-bottom: 0;
		font-size: 7vw;
		@include breakpoint(small only) {
			font-size: 9vw;
		}
	}
	h4 {
		letter-spacing: 15px;
		margin-top: 2vh;
		font-size: 2.5vw;
		@include breakpoint(small only) {
			font-size: 5vw;
		}
	}

	.title-holder {
		top: 50%;
	}	
}


.brief-container {
	background-color: #fff;
	padding-bottom: 5.555rem;
	padding-top:  5.555rem;
}

// p.large-brief {
// 	font-size: 30px;
// 	// font-weight: 300;
// 	font-weight: lighter;
// 	max-width: 1000px;
// 	margin-top: 5%;
// 	margin-bottom: 5%;
//   margin-left: auto;
//   margin-right: auto;

// 	> b {
// 		font-weight: 500;
// 	}
// 	@include breakpoint(small only) {
// 		font-size: 19px;
// 	}
// 	a {
// 		border-bottom: 1px solid rgba(0,0,0,0.1);
// 		// padding-bottom: 2px;
// 		color: rgba(0,0,0,0.8);
// 		font-weight: bold;
// 	}
// 	span {
// 		color: rgba(0,0,0,0.25);
// 		font-size: 40px;
//     	line-height: 30px;
// 	}
// }
.brief-quoter {
	margin-top: 1em;
	margin-bottom: 10%;
	font-weight: lighter;
	color: rgba(0,0,0,0.4);
	font-size: 30px;
	@include breakpoint(small only) {
		font-size: 19px;
	}
}
.readmore {
	height: 100px;
	overflow: hidden;

	&:after {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		height: 100px;
	    background: rgba(255,255,255,0);
		background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
		background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,0)), color-stop(100%, rgba(255,255,255,1)));
		background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
		background: -o-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
		background: -ms-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
		background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0 );
	}
}




///////////////////////////////////////////


.portfolio {

	font-size: 80%;
	padding-bottom: 5.555rem;
	padding-top: 5.555rem;

	// background-color: #fff;
	p, 
	.menu {
		margin-top: 5%;
		li a {
			color: rgba(255,255,255,0.7);
		}
	}
	p {
		color: rgba(0,0,0,0.8);
		> b {
			color: #000;
		}
	}
	h4 {
		margin-bottom: 1rem;
	}
	p + h4 {
		margin-top: 50px;
	}
	p b {
		font-weight: 500;
	}
	h4 {
		color: #000;
	}

	&.container--dark {

		color: #fff;
		
		h4 {
			color: #fff;
		}
		p {
			color: rgba(255,255,255,0.8);
			> b {
				color: #fff;
			}
		}
	}
	.portfolio-info {
		list-style: none;
		padding: 0;
		margin: 0 0 4.444rem 0;
		text-align: left;

		li {
			display: inline-block;
			margin-bottom: 3.333rem;
			width: 44%;
			vertical-align: top;
			
			&:nth-child(even) {
				margin-left: 7%;
				@include breakpoint(small only) {
					margin-left: 0;
				}
			}
			&.long {
				width: 100%;
				margin-left: 0;
			}
			p {
				margin-top: 0;
				margin-bottom: 0;
			}
			&.awards {
				p {
					margin-bottom: 0.5rem;
				}
			}
		}
	}
	p + .portfolio-info {
		margin-top: 100px;
	}
	.video-holder {
		height: 0;
		padding-bottom: 75%;
		position: relative;
		width: 100%;
		overflow: hidden;
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
	.laptop-shape {
		padding: 4%;
		box-shadow: 0 0 0 2px #555;
	    border-top-left-radius: 3% 4%;
	    border-top-right-radius: 3% 4%;
	    position: relative;
	    margin-bottom: 5%;
	    margin-top: 3%;

	    &:after {
	    	content: '';
	    	display: block;
	    	position: absolute;
	    	bottom: -3%;
	    	margin-bottom: -2px;
	    	left: -10%;
	    	right: -10%;
	    	height: 3%;
	    	box-shadow: 0 0 0 2px #555;
		    border-top-left-radius: 1em;
		    border-top-right-radius: 1em;
		    border-bottom-left-radius: 10em;
		    border-bottom-right-radius: 10em;
	    }
	}
	.tablet-shape {
		padding: 4%;
		box-shadow: 0 0 0 2px #555;
	    border-radius: 1.5vw;
	    position: relative;
	    margin-bottom: 5%;
	    margin-top: 3%;
	    // background-color: rgba(255,255,255,0.1);
	    .video-holder {
	    	padding-bottom: 57%;
	    }
	}
	.portfolio-thumbs {
		// margin-top: 100px;
	}
	.portfolio-thumb {
		display: block;
		overflow: hidden;
		padding-bottom: 66%;
		height:0;
		width: 100%;
		position: relative;
		margin-bottom: 30px;
		background-size: cover;
		background-position: center center;
		
		&.portrait {
			padding-bottom: 132%;
			padding-top: 30px;
		}
	}
	.portfolio-logo {
		margin-bottom: 100px;
	}
}



///////////////////////////////////////////

.title {
	// font-weight: lighter;
	letter-spacing: 3px;
	margin-bottom: 2em;
	margin-top: 1em;
}

.selected-works {
	font-size: 30px;

	h3 {
		// font-weight: lighter;
		padding-bottom: 20px;
	}
	ul.menu {
		li {
			a {
				font-weight: lighter;
				min-height: 150px;
				line-height: 70px;
				&:hover {
					background-color: rgba(0,0,0,0.05);
				}
			}
		}
	}
}

.cta {
	background-color: $black;
	position: relative;
	color: #fff;
	// height: calc(100vh - 140px);
    height: 100vh;
	// max-height: 50vw;
	// overflow: hidden;
	// background-size: cover;
	// background-repeat: no-repeat;
	// background-position: center center;
	// background: url('../img/peedu-tuisk-pointing.jpg') no-repeat center;

	// font-size: 30px;
	// @include breakpoint(small only) {
	// 	font-size: 20px;
	// }
	// @include breakpoint(large) {
	// 	font-size: 2.5vw;
	// }

	// @include breakpoint(small only) {
	// 	height: 100vh;
	// 	max-height: 100vh;
	// 	// font-size: 140%;
	// }

	// &:after {
	// 	content: '';
	// 	display: block;
	// 	position: absolute;
	// 	top: 0;
	// 	right: 0;
	// 	bottom: 0;
	// 	left: 0;
	// 	z-index: 1;
	// 	background-color: rgba(0,0,0,0.3);
	// 	transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);
	// }

	// &:before {
	// 	z-index: 2;
	// 	content: '';
	// 	display: block;
	// 	position: absolute;
	// 	top: 0;
	// 	right: 0;
	// 	bottom: 0;
	// 	left: 0;
	// 	// background-color: rgba(0,0,0,0.8);
	// 	// background: linear-gradient(45deg, $pinkish, $primary-color);
	// 	opacity: 0.4;
	// 	// animation: fadetodark 1s cubic-bezier(0.5, 0.2, 0.9, 0.4);
	// }
    .cta-content {
        position: absolute;
        z-index: 6;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
    }

	h2 {
		display: inline-block;
		color: white;
        font-weight: bold;
	}
	p {
		// font-weight: lighter;
		width: 80%;
		margin-left: 10%;
		margin-right: 10%;
		color: rgba(255,255,255,0.8);
	}
}

.video-background {
    position: relative;
    // top: 0;
    // left: 0;
    width: 100%;
    height: 100%;
    // z-index: 5;
    overflow: hidden;
    // filter: grayscale(100%) brightness(30%);
    // mix-blend-mode: difference;
    // background: black;

    .video-overlay-dark {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        // background: linear-gradient(
     //    45deg,
        //     hsla(0, 0%, 0%, 1) 0%,
        //     hsla(0, 0%, 0%, 0.5) 50%,
        //     hsla(0, 0%, 0%, 1) 100%
     //     );
        // background-position: top left;
        opacity: 0.4;
        background: black;
        z-index: 3;
    }

    video {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;

    @media only screen and (min-height: 56.25vw) {
        height: 100%;
    }
    @media only screen and (max-height: 56.25vw) {
        width: 100%;
    }
    }
}

///////////////////////////////////////////

.featherlight-next, 
.featherlight-previous {
	position: fixed;
	top: 0;
	span {
		font-size: 40px;
		text-shadow: none;
		display: block;
		opacity: 0.5;
	}
	&:hover span {
		opacity: 1;
	}
}
.featherlight-next {
	right: 0;
	left: 90%;
}
.featherlight-previous {
	left: 0;
	right: 90%;
}
.featherlight-close-icon.featherlight-close {
	position: fixed;
	background: transparent;
	color: #fff;
	opacity: 0.5;
	font-size: 30px;
	top: 2vw;
	right: 2vw;
	&:hover {
		opacity: 1;
	}
}

///////////////////////////////////////////


footer {
	font-size: 30px;
	@include breakpoint(small only) {
		font-size: 30px;
	}
	@include breakpoint(large) {
		font-size: 2.5vw;
	}

	background-color: #fff;
	
	a {
		display: block;
		min-height: 150px;
		line-height: 150px;
		width: 100%;
		// color: rgba(0,0,0,0.8);
		font-weight: lighter;
		font-size: inherit;

		&:hover {
			background-color: rgba(0,0,0,0.05);
		}
	}
	ul.menu {
		// margin: 0;
		// list-style: none;

		a {
		}
		li {
			// display: inline-block;
			// width: 16.666%;
			a {
				// color: rgba(0,0,0,0.8);
				line-height: 150px;
				padding: 0;
				display: block;
			}
		}
	}
}